import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

const OfferForm = ({
  currencies,
  totalCurrencies,
  username,
  user,
  fetchCurrencies,
  onOfferFormChange,
}) => {
  const [selectedUser, setSelectedUser] = useState("");
  const [offerCurrency, setOfferCurrency] = useState("");
  const [offerAmount, setOfferAmount] = useState("");
  const [recCurrency, setRecCurrency] = useState("");
  const [recAmount, setRecAmount] = useState("");

  const createOffer = async () => {
    // Convert inputs into an offer object
    const offer = {
      creator: username,
      target: selectedUser.toLowerCase(),
      offerCurrency,
      offerAmount: Number(offerAmount),
      payCurrency: recCurrency,
      payAmount: Number(recAmount),
    };

    try {
      if (recAmount > 99999) {
        return;
      }

      if (selectedUser.toLowerCase() === username.toLowerCase()) {
        return;
      }

      if (recCurrency === "" || offerCurrency === "") {
        return;
      }

      if (recCurrency === offerCurrency) {
        return;
      }

      const index = currencies.findIndex(
        (currency) => currency[0] === offerCurrency
      );

      if (index === -1) {
        return;
      }

      if (offerAmount <= 0) {
        return;
      }

      if (offerAmount > currencies[index][1] || recAmount <= 0) {
        return;
      }

      const recNameRef = firebase
        .firestore()
        .collection("usernames")
        .doc(selectedUser.toLowerCase());
      const recNameDoc = await recNameRef.get();

      if (!recNameDoc.exists) {
        return;
      }

      const userDocRef = firebase.firestore().collection("users").doc(user.uid);
      const userDoc = await userDocRef.get();

      if (userDoc.exists) {
        const userData = userDoc.data();
        const currencies = userData.currencies || {};
        const enteredAmount = parseInt(offerAmount, 10);

        if (currencies.hasOwnProperty(offerCurrency)) {
          // Currency already exists, add the entered amount to the current value
          const currentValue = currencies[offerCurrency];
          const newValue = currentValue - enteredAmount;
          if (newValue < 0) {
            return;
          }

          if (newValue === 0) {
            delete currencies[offerCurrency];
          } else {
            currencies[offerCurrency] = newValue;
          }
        }

        await userDocRef.update({ currencies });

        fetchCurrencies(user.uid);
      }

      // Create a document in Firestore
      await firebase.firestore().collection("offers").add(offer);

      userDocRef.update({
        friends: firebase.firestore.FieldValue.arrayUnion(
          selectedUser.toLowerCase()
        ),
      });

      // Reset the form fields
      setOfferCurrency("");
      setOfferAmount("");
      setRecCurrency("");
      setRecAmount("");
      onOfferFormChange();
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };

  return (
    <div className="send-currency-form margin">
      <b>Send offer to </b>
      <input
        type="text"
        value={selectedUser}
        onChange={(e) => setSelectedUser(e.target.value)}
        placeholder="$user"
        className="username-input"
        list="friends"
      />

      <br />

      <select
        className="select-menu"
        value={offerCurrency}
        onChange={(e) => setOfferCurrency(e.target.value)}
      >
        <option value="">Currency</option>
        {typeof currencies === "object" &&
          currencies !== null &&
          Object.entries(currencies).map(([position, value]) => (
            <option key={value[0]} value={value[0]}>
              {value[0]}
            </option>
          ))}
      </select>
      <input
        type="number"
        value={offerAmount}
        onChange={(e) => setOfferAmount(e.target.value)}
        placeholder="Amount"
        className="amount-input"
      />
      <br />
      <b className="center"> for </b>
      <br />
      <select
        className="select-menu"
        value={recCurrency}
        onChange={(e) => setRecCurrency(e.target.value)}
      >
        <option value="">Currency</option>
        {typeof totalCurrencies === "object" &&
          totalCurrencies !== null &&
          Object.entries(totalCurrencies).map(([position, value]) => (
            <option key={value[0]} value={value[0]}>
              {value[0]}
            </option>
          ))}
      </select>
      <input
        type="number"
        value={recAmount}
        onChange={(e) => setRecAmount(e.target.value)}
        placeholder="Amount"
        className="amount-input"
      />

      <br />

      <button className="send-currency-button" onClick={createOffer}>
        Send Offer
      </button>
    </div>
  );
};

export default OfferForm;
