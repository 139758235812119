import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import OfferList from "./components/OfferList";
import OfferForm from "./components/OfferForm";
import BalancePopup from "./components/BalancePopup";
import {isMobile} from 'react-device-detect';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCsI0VvH9b8aK_ekmThxq_v4ohgKoeCMjw",
  authDomain: "jaredcoin-7ce2e.firebaseapp.com",
  projectId: "jaredcoin-7ce2e",
  storageBucket: "jaredcoin-7ce2e.appspot.com",
  messagingSenderId: "874603544425",
  appId: "1:874603544425:web:d2bbd17360f8cef2b1c7f8",
  measurementId: "G-F8TKZ7TYGP",
};

firebase.initializeApp(firebaseConfig);

const App = () => {
  const [user, setUser] = useState(null);
  const [currencies, setCurrencies] = useState({});
  const [totalCurrencies, setTotalCurrencies] = useState({});
  const [selectedCurrencyAdd, setSelectedCurrencyAdd] = useState("");
  const [selectedAmountAdd, setSelectedAmountAdd] = useState("");
  const [selectedCurrencySend, setSelectedCurrencySend] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedAmountSend, setSelectedAmountSend] = useState("");
  const [currencyCreate, setCurrencyCreate] = useState("");
  const [selectedUsername, setSelectedUsername] = useState("");
  const [username, setUsername] = useState("");
  const [currenciesHistory, setCurrenciesHistory] = useState({});
  const [tradeOrders, setTradeOrders] = useState({});
  const [outFilter, setOutFilter] = useState({});
  const [inFilter, setInFilter] = useState({});
  const [currencyTradeOut, setCurrencyTradeOut] = useState("");
  const [currencyTradeIn, setCurrencyTradeIn] = useState("");
  const [tradeAmountOut, setTradeAmountOut] = useState("");
  const [tradeAmountIn, setTradeAmountIn] = useState("");
  const [friends, setFriends] = useState([]);
  const [offerFormChange, setOfferFormChange] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      console.log("App Load");
      if (authUser) {
        setOutFilter("");
        setInFilter("");
        fetchTradeOrders();
        setUser(authUser);
        checkHasUsername(authUser.uid);
        fetchCurrencies(authUser.uid);
      } else {
        setUser(null);
        setCurrencies({});
      }
    });

    return () => unsubscribe();
  }, []);

  function refreshPage() {
    window.location.reload(false);
  }

  const fetchCurrencies = async (userId) => {
    try {
      fetchTotalCurrencies();
      const userDocRef = firebase.firestore().collection("users").doc(userId);
      const userDoc = await userDocRef.get();

      if (userDoc.exists) {
        const userData = userDoc.data();
        const currencies = userData.currencies || {};

        const sortedCurrencies = Object.entries(currencies).sort(([a], [b]) =>
          a.localeCompare(b)
        );

        setCurrencies(sortedCurrencies);

        const friends = userData.friends || [];
        setFriends(friends);
      } else {
        console.log("User document does not exist");
      }
    } catch (error) {
      console.error("Error fetching currencies:", error);
    }
  };

  const fetchTotalCurrencies = async () => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection("currencies")
        .get();

      const currenciesData = {};
      snapshot.forEach((doc) => {
        const currencyId = doc.id;
        const currencyData = doc.data();
        currenciesData[currencyId] = currencyData;
      });

      const sortedCurrencies = Object.entries(currenciesData).sort(
        ([a], [b]) => {
          // Sort the keys (currency IDs) in alphabetical order
          return a.localeCompare(b);
        }
      );

      setTotalCurrencies(sortedCurrencies);

      const historySnapshot = await firebase
        .firestore()
        .collection("history")
        .get();

      const historiesData = {};
      historySnapshot.forEach((doc) => {
        const timestamp = doc.id;
        const historyData = doc.data();
        historiesData[timestamp] = historyData;
      });

      const sortedHistory = Object.entries(historiesData).sort(([a], [b]) => {
        return a.localeCompare(b);
      });

      setCurrenciesHistory(sortedHistory);
    } catch (error) {
      console.error("Error fetching currencies:", error);
    }
  };

  const fetchTradeOrders = async () => {
    try {
      const snapshot = await firebase.firestore().collection("trades").get();

      const tradesData = {};
      const ownTrades = {};
      snapshot.forEach((doc) => {
        const tradeId = doc.id;
        const tradeData = doc.data();
        tradesData[tradeId] = tradeData;

        if (tradeData["creator"] === username) {
          ownTrades[tradeId] = tradeData;
        }
      });

      const sortedTrades = Object.entries(tradesData).sort(([a], [b]) => {
        // produce randomly maybe?
        return a.localeCompare(b);
      });

      setTradeOrders(sortedTrades);
    } catch (error) {
      console.error("Error fetching trades:", error);
    }
  };

  const createTradeOrder = async () => {
    const tradeDocRef = await firebase.firestore().collection("trades").doc();

    if (currencyTradeIn === "" || currencyTradeOut === "") {
      return;
    }

    if (currencyTradeIn === currencyTradeOut) {
      return;
    }

    const index = currencies.findIndex(
      (currency) => currency[0] === currencyTradeOut
    );
    if (index === -1) {
      return;
    }

    if (tradeAmountOut <= 0) {
      return;
    }

    if (tradeAmountOut > currencies[index][1] || tradeAmountIn <= 0) {
      return;
    }

    const userDocRef = firebase.firestore().collection("users").doc(user.uid);
    const userDoc = await userDocRef.get();

    if (userDoc.exists) {
      const userData = userDoc.data();
      const currencies = userData.currencies || {};
      const enteredAmount = parseInt(tradeAmountOut, 10);

      if (currencies.hasOwnProperty(currencyTradeOut)) {
        // Currency already exists, add the entered amount to the current value
        const currentValue = currencies[currencyTradeOut];
        const newValue = currentValue - enteredAmount;
        if (newValue < 0) {
          return;
        }

        if (newValue === 0) {
          delete currencies[currencyTradeOut];
        } else {
          currencies[currencyTradeOut] = newValue;
        }
      }

      await userDocRef.update({ currencies });

      fetchCurrencies(user.uid);
    }

    tradeDocRef.set({
      creator: username,
      recAmount: tradeAmountOut,
      recCurrency: currencyTradeOut,
      payAmount: tradeAmountIn,
      payCurrency: currencyTradeIn,
    });

    setCurrencyTradeIn("");
    setCurrencyTradeOut("");
    setTradeAmountIn("");
    setTradeAmountOut("");

    fetchTradeOrders();
  };

  const executeTrade = async (tradeId) => {
    const tradeDocRef = firebase.firestore().collection("trades").doc(tradeId);
    const tradeDoc = await tradeDocRef.get();

    if (tradeDoc.exists) {
      const tradeData = tradeDoc.data();
      const index = currencies.findIndex(
        (currency) => currency[0] === tradeData.payCurrency
      );

      if (index === -1) {
        // Don't have any currency
        return;
      }

      if (tradeData.payAmount > currencies[index][1]) {
        return;
      }

      const userDocRef = firebase.firestore().collection("users").doc(user.uid);
      const userDoc = await userDocRef.get();

      if (userDoc.exists) {
        const userData = userDoc.data();
        let currencies = userData.currencies || {};
        const enteredAmount = parseInt(tradeData.recAmount, 10);

        if (currencies.hasOwnProperty(tradeData.recCurrency)) {
          // Currency already exists, add the entered amount to the current value
          const currentValue = currencies[tradeData.recCurrency];
          const newValue = currentValue + enteredAmount;
          if (newValue < 0) {
            return;
          }
          currencies[tradeData.recCurrency] = newValue;
        } else {
          // Currency doesn't exist, add it with the entered amount as the value
          currencies[tradeData.recCurrency] = enteredAmount;
        }

        if (currencies.hasOwnProperty(tradeData.payCurrency)) {
          // Currency already exists, add the entered amount to the current value
          const currentValue = currencies[tradeData.payCurrency];
          const enteredAmount = parseInt(tradeData.payAmount, 10);
          const recNameRef = firebase
            .firestore()
            .collection("usernames")
            .doc(tradeData.creator.toLowerCase());
          const recNameDoc = await recNameRef.get();
          if (recNameDoc.exists) {
            const recipientRef = firebase
              .firestore()
              .collection("users")
              .doc(recNameDoc.data().userId);
            const recipientDoc = await recipientRef.get();

            if (recipientDoc.exists) {
              const recipientData = recipientDoc.data();
              const recCurrencies = recipientData.currencies || {};

              if (recCurrencies.hasOwnProperty(tradeData.payCurrency)) {
                recCurrencies[tradeData.payCurrency] =
                  recCurrencies[tradeData.payCurrency] + enteredAmount;
              } else {
                recCurrencies[tradeData.payCurrency] = enteredAmount;
              }

              currencies = recCurrencies;
              recipientRef.update({ currencies });
              currencies = userData.currencies || {};
            }
          }

          // Take currency from user
          currencies[tradeData.payCurrency] = currentValue - enteredAmount;
          if (currencies[tradeData.payCurrency] === 0) {
            delete currencies[tradeData.payCurrency];
          }
        }

        await userDocRef.update({ currencies });
        removeTrade(tradeId);
        fetchCurrencies(user.uid);
      }
    }

    fetchTradeOrders();
  };

  const cancelTrade = async (tradeId) => {
    const tradeDocRef = firebase.firestore().collection("trades").doc(tradeId);
    const tradeDoc = await tradeDocRef.get();

    if (tradeDoc.exists) {
      const tradeData = tradeDoc.data();

      const userDocRef = firebase.firestore().collection("users").doc(user.uid);
      const userDoc = await userDocRef.get();

      if (userDoc.exists) {
        const userData = userDoc.data();
        const currencies = userData.currencies || {};
        const enteredAmount = parseInt(tradeData.recAmount, 10);

        if (currencies.hasOwnProperty(tradeData.recCurrency)) {
          // Currency already exists, add the entered amount to the current value
          const currentValue = currencies[tradeData.recCurrency];
          const newValue = currentValue + enteredAmount;
          if (newValue < 0) {
            return;
          }
          currencies[tradeData.recCurrency] = newValue;
        } else {
          // Currency doesn't exist, add it with the entered amount as the value
          currencies[tradeData.recCurrency] = enteredAmount;
        }

        await userDocRef.update({ currencies });
        removeTrade(tradeId);

        fetchCurrencies(user.uid);
      }
    }

    fetchTradeOrders();
  };

  const removeTrade = async (tradeId) => {
    try {
      const tradeRef = firebase.firestore().collection("trades").doc(tradeId);
      await tradeRef.delete();
      fetchTradeOrders();
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const addCurrency = async () => {
    if (selectedCurrencyAdd.trim() === "") {
      return;
    }
    
    if (selectedAmountAdd <= 0) {
      return;
    }

    try {
      const userDocRef = firebase.firestore().collection("users").doc(user.uid);
      const userDoc = await userDocRef.get();

      if (userDoc.exists) {
        const userData = userDoc.data();
        const currencies = userData.currencies || {};

        if (currencies.hasOwnProperty(selectedCurrencyAdd)) {
          // Currency already exists, add the entered amount to the current value
          const currentValue = currencies[selectedCurrencyAdd];
          const enteredAmount = parseInt(selectedAmountAdd, 10);
          const newValue = currentValue + enteredAmount;
          if (newValue < 0) {
            return;
          }
          currencies[selectedCurrencyAdd] = newValue;
        } else {
          // Currency doesn't exist, add it with the entered amount as the value
          const enteredAmount = parseInt(selectedAmountAdd, 10);
          if (enteredAmount < 0) {
            return;
          }
          currencies[selectedCurrencyAdd] = enteredAmount;
        }

        await userDocRef.update({ currencies });

        const increment = firebase.firestore.FieldValue.increment(
          parseInt(selectedAmountAdd, 10)
        );
        const currencyDocRef = firebase
          .firestore()
          .collection("currencies")
          .doc(selectedCurrencyAdd);
        await currencyDocRef.update({ balance: increment });

        const timestamp = new Date();
        const historyRef = firebase
          .firestore()
          .collection("history")
          .doc(timestamp.getTime().toString());

        historyRef
          .set({
            timestamp: timestamp,
            action: parseInt(selectedAmountAdd, 10),
            currency: selectedCurrencyAdd,
          })
          .catch((error) => {
            console.error("Error creating history document:", error);
          });

        setSelectedCurrencyAdd("");
        fetchCurrencies(user.uid);
      } else {
        console.log("User document does not exist");
      }
    } catch (error) {
      console.error("Error adding currency:", error);
    }
    setSelectedAmountAdd("");
  };

  const handleLogin = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const user = result.user;

        // Check if the user document exists
        const userRef = firebase.firestore().collection("users").doc(user.uid);
        userRef.get().then((doc) => {
          if (doc.exists) {
            // User document exists, fetch currencies
            setUser(user);
            fetchCurrencies(user.uid);
          } else {
            // User document doesn't exist, create a new document
            userRef
              .set({
                displayName: user.displayName,
                friends: [],
              })
              .then(() => {
                setUser(user);
                setCurrencies({}); // Change to an empty object here
              })
              .catch((error) => {
                console.error("Error creating user document:", error);
              });
          }
        });
      })
      .catch((error) => {
        console.error("Login error:", error);
      });
  };

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        setCurrencies([]);
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  function getRandomColor() {
    return "hsla(" + Math.random() * 360 + ", 100%, 50%, 1)";
  }

  const createCurrency = async () => {
    // Check if the user document exists
    const currencyRef = firebase
      .firestore()
      .collection("currencies")
      .doc(currencyCreate);
    currencyRef.get().then((doc) => {
      if (doc.exists) {
        // Currency already exists, who cares
        return;
      } else {
        // User document doesn't exist, create a new document
        currencyRef
          .set({
            currency: currencyCreate,
            balance: 0,
            color: getRandomColor(),
            // You can add additional user data here if needed
          })
          .catch((error) => {
            console.error("Error creating currency document:", error);
          });

        const timestamp = new Date();
        const historyRef = firebase
          .firestore()
          .collection("history")
          .doc(timestamp.getTime().toString());

        historyRef
          .set({
            timestamp: timestamp,
            action: 0,
            currency: currencyCreate,
          })
          .catch((error) => {
            console.error("Error creating history document:", error);
          });

        setCurrencyCreate("");
        fetchTotalCurrencies();
      }
    });
  };

  const checkHasUsername = async (userId) => {
    try {
      const userDocRef = firebase.firestore().collection("users").doc(userId);
      const userDoc = await userDocRef.get();

      if (userDoc.exists) {
        const userData = userDoc.data();
        if (!userData.hasOwnProperty("username")) {
          setUsername(false);
          return;
        }
        setUsername(userData["username"]);
      } else {
        console.log("User document does not exist");
      }
    } catch (error) {
      console.error("Error fetching currencies:", error);
    }
  };

  const createUsername = async () => {
    try {
      const usernameDocRef = firebase
        .firestore()
        .collection("usernames")
        .doc("$".concat(selectedUsername).toLowerCase());
      const usernameDoc = await usernameDocRef.get();

      if (usernameDoc.exists) {
        return;
      }

      const usernameRegex = /^[a-zA-Z0-9_]{3,16}$/;
      if (!usernameRegex.test(selectedUsername)) {
        return;
      }

      const userDocRef = firebase.firestore().collection("users").doc(user.uid);
      const userDoc = await userDocRef.get();

      if (userDoc.exists) {
        userDocRef
          .update({
            username: "$".concat(selectedUsername).toLowerCase(),
          })
          .catch((error) => {
            console.error("Error setting username", error);
          });

        await usernameDocRef
          .set({
            userId: user.uid,
            photoURL: user.photoURL,
          })
          .catch((error) => {
            console.error("Error creating username document:", error);
          });

        checkHasUsername(user.uid);
        
        refreshPage()
      } else {
        console.log("User document does not exist");
      }
    } catch (error) {
      console.error("Error setting username:", error);
    }
  };

  const sendCurrency = async () => {
    try {
      const userDocRef = firebase.firestore().collection("users").doc(user.uid);
      const userDoc = await userDocRef.get();

      if (selectedAmountSend < 0) {
        return;
      }

      if (username === selectedUser) {
        return;
      }

      if (userDoc.exists) {
        const userData = userDoc.data();
        let currencies = userData.currencies || {};

        if (currencies.hasOwnProperty(selectedCurrencySend)) {
          // Currency already exists, add the entered amount to the current value
          const currentValue = currencies[selectedCurrencySend];
          const enteredAmount = parseInt(selectedAmountSend, 10);

          if (enteredAmount <= currentValue) {
            // FIND USER DOC and add currnecy
            // username to find uid
            const recNameRef = firebase
              .firestore()
              .collection("usernames")
              .doc(selectedUser.toLowerCase());
            const recNameDoc = await recNameRef.get();

            if (recNameDoc.exists) {
              const recipientRef = firebase
                .firestore()
                .collection("users")
                .doc(recNameDoc.data()["userId"]);
              const recipientDoc = await recipientRef.get();

              if (recipientDoc.exists) {
                const recipientData = recipientDoc.data();
                const recCurrencies = recipientData.currencies || {};

                if (recCurrencies.hasOwnProperty(selectedCurrencySend)) {
                  recCurrencies[selectedCurrencySend] =
                    recCurrencies[selectedCurrencySend] + enteredAmount;
                } else {
                  recCurrencies[selectedCurrencySend] = enteredAmount;
                }

                currencies = recCurrencies;
                recipientRef.update({ currencies });
                currencies = userData.currencies || {};
              }
          
              // Take currency from user
              currencies[selectedCurrencySend] = currentValue - enteredAmount;
              if (currencies[selectedCurrencySend] === 0) {
                delete currencies[selectedCurrencySend];
              }
            } else {
              return;
            }

            setSelectedAmountSend("");
            setSelectedCurrencySend("");
            setSelectedUser("");

            userDocRef.update({
              friends: firebase.firestore.FieldValue.arrayUnion(
                selectedUser.toLowerCase()
              ),
            });

            userDocRef.update({ currencies });
          }
        }
        fetchCurrencies(user.uid);
      } else {
        console.log("User document does not exist");
      }
    } catch (error) {
      console.error("Error setting username:", error);
    }
  };

  const handleOfferFormChange = () => {
    setOfferFormChange(!offerFormChange);
  };

  return (
    <div className="app">
      {user ? (
        <div>
          <div className={isMobile ? "profile-bar" : "profile-bar"}>
            <table className={isMobile ? "" : "padding-table"}>
              <tbody>
                <tr>
                {isMobile ? (<></>) : (<td></td>)}
                  <td className="centered-cell" >
                    <table className="profile-table ">
                      <tbody>
                        <tr>
                          <td className="profile-picture-cell">
                            {user.photoURL && (
                              <div>
                                <img
                                  src={user.photoURL}
                                  alt="Profile"
                                  className="invert profile-picture"
                                />
                              </div>
                            )}
                          </td>
                          <td className="profile-table-cell">
                            <table className="profile-table-inner">
                              <tbody>
                                <tr>
                                  <td className="logout-align">
                                    <button
                                      className="no-left-margin"
                                      onClick={handleLogout}
                                    >
                                      Logout
                                    </button>
                                  </td>
                                  <td className="right-align" rowSpan={2}>
                                    <img
                                      src={require("./images/logo.png")}
                                      className="logo invert "
                                      alt="Logo for the Goob Exchange"
                                    />
                                  </td>
                                </tr>
                                <tr classnName="user-info-row">
                                  <td className="info-align">
                                    {user.displayName}
                                    <br />
                                    {username && (
                                      <b className="username">{username}</b>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  {isMobile ? (<></>) : (<td></td>)}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="body-content">
            <table className={isMobile ? "padding-table" : "padding-table"}>
              <tbody>
                <tr>
                {isMobile ? (<></>) : (<td></td>)}
                  <td className="centered-cell">
                    {/* Balances */}

                    <table className="body-table">
                      <tbody>
                        {isMobile ? (<tr>
                          {/*worst solution, just made second version of render for mobile users  */}
                          <td className="body-cell">
                            <h2>Balances</h2>
                            <table className="currencies-list">
                              <tbody>
                                {Array.isArray(currencies) &&
                                currencies.length > 0 ? (
                                  currencies.map(([currency, amount]) => {
                                    const colorIndex = totalCurrencies.find(
                                      ([curr]) => curr === currency
                                    )?.[1]?.color;

                                    return (
                                      <tr
                                        key={currency}
                                        className="balance-entry"
                                      >
                                        <td className="balance-td">
                                          <span
                                            className="dot dot-balance"
                                            style={{
                                              backgroundColor: colorIndex,
                                            }}
                                          ></span>
                                          <b>{currency}</b>{" "}
                                        </td>
                                        <td className="balance-td">{amount}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={3}>No balances found.</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            {username ? (
                              <div>
                                <h2 className="section-heading margin">
                                  Transactions
                                </h2>
                                <div className="send-currency-form">
                                  <b>Send </b>

                                  <input
                                    type="number"
                                    value={selectedAmountSend}
                                    onChange={(e) =>
                                      setSelectedAmountSend(e.target.value)
                                    }
                                    placeholder="Amount"
                                    className="amount-input amount-input-skinny"
                                  />

                                  <select
                                    className="select-menu"
                                    value={selectedCurrencySend}
                                    onChange={(e) =>
                                      setSelectedCurrencySend(e.target.value)
                                    }
                                  >
                                    <option value="">Currency</option>
                                    {typeof currencies === "object" &&
                                      currencies !== null &&
                                      Object.entries(currencies).map(
                                        ([position, value]) => (
                                          <option
                                            key={value[0]}
                                            value={value[0]}
                                          >
                                            {value[0]}
                                          </option>
                                        )
                                      )}
                                  </select>

                                  <b> to </b>

                                  <input
                                    type="text"
                                    value={selectedUser}
                                    onChange={(e) =>
                                      setSelectedUser(e.target.value)
                                    }
                                    placeholder="$user"
                                    className="username-input"
                                    list="friends"
                                  />
                                  <datalist id="friends">
                                    {friends.map((friend) => (
                                      <option value={friend} key={friend} />
                                    ))}
                                  </datalist>

                                  <br />
                                  <button
                                    className="send-currency-button"
                                    onClick={sendCurrency}
                                  >
                                    Send
                                  </button>
                                </div>

                                <OfferForm
                                  currencies={currencies}
                                  totalCurrencies={totalCurrencies}
                                  username={username}
                                  friends={friends}
                                  setFriends={setFriends}
                                  user={user}
                                  fetchCurrencies={fetchCurrencies}
                                  onOfferFormChange={handleOfferFormChange}
                                />

                                <OfferList
                                  username={username}
                                  fetchCurrencies={fetchCurrencies}
                                  currencies={currencies}
                                  user={user}
                                  offerFormChange={offerFormChange}
                                  totalCurrencies={totalCurrencies}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            {username ? (
                              <div>
                                {/* Trades */}
                                <h2 className="section-heading">Buy Orders</h2>

                                {/* Display Trades */}
                                <div className="trade-section">
                                  <div className="create-trade">
                                    <b className="create-trade-text">
                                      Create Trade
                                    </b>
                                    <table className="trade-table">
                                      <tbody>
                                        {/* Only show Trades */}
                                        <tr className="create-trade-form">
                                          <td>
                                            <select
                                              className="select-menu secondary-input"
                                              value={currencyTradeOut}
                                              onChange={(e) =>
                                                setCurrencyTradeOut(
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option value="">Offering</option>
                                              {typeof currencies === "object" &&
                                                currencies !== null &&
                                                Object.entries(currencies).map(
                                                  ([position, value]) => (
                                                    <option
                                                      key={value[0]}
                                                      value={value[0]}
                                                    >
                                                      {value[0]}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                          </td>
                                          <td>
                                            <input
                                              type="number"
                                              value={tradeAmountOut}
                                              onChange={(e) =>
                                                setTradeAmountOut(
                                                  e.target.value
                                                )
                                              }
                                              placeholder="Amount"
                                              className="amount-input secondary-input"
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <span>&#8594;</span>{" "}
                                          </td>
                                          <td>
                                            <select
                                              className="select-menu secondary-input"
                                              value={currencyTradeIn}
                                              onChange={(e) =>
                                                setCurrencyTradeIn(
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option value="">Receive</option>
                                              {typeof totalCurrencies ===
                                                "object" &&
                                                totalCurrencies !== null &&
                                                Object.entries(
                                                  totalCurrencies
                                                ).map(([position, value]) => (
                                                  <option
                                                    key={value[0]}
                                                    value={value[0]}
                                                  >
                                                    {value[0]}
                                                  </option>
                                                ))}
                                            </select>
                                          </td>
                                          <td>
                                            <input
                                              type="number"
                                              value={tradeAmountIn}
                                              onChange={(e) =>
                                                setTradeAmountIn(e.target.value)
                                              }
                                              placeholder="Amount"
                                              className="amount-input secondary-input"
                                            />
                                          </td>

                                          <td>
                                            {" "}
                                            <button
                                              className="execute-button"
                                              onClick={createTradeOrder}
                                            >
                                              Create Order
                                            </button>{" "}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>

                                  <div className="trade-list">
                                    <table className="trade-table">
                                      <tbody>
                                        <tr className="trade-row">
                                          <td className="trade-cell">
                                            <select
                                              className="select-menu"
                                              value={outFilter}
                                              onChange={(e) =>
                                                setOutFilter(e.target.value)
                                              }
                                            >
                                              <option value="">Payment</option>
                                              {typeof currencies === "object" &&
                                                currencies !== null &&
                                                Object.entries(currencies).map(
                                                  ([position, value]) => (
                                                    <option
                                                      key={value[0]}
                                                      value={value[0]}
                                                    >
                                                      {value[0]}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                          </td>
                                          <td className="trade-cell">
                                            <text>
                                            Amount
                                            </text>
                                          </td>
                                          <td className="trade-cell"> </td>
                                          <td className="trade-cell">
                                            <select
                                              className="select-menu"
                                              value={inFilter}
                                              onChange={(e) =>
                                                setInFilter(e.target.value)
                                              }
                                            >
                                              <option value="">
                                                Receiving
                                              </option>
                                              {typeof totalCurrencies ===
                                                "object" &&
                                                totalCurrencies !== null &&
                                                Object.entries(
                                                  totalCurrencies
                                                ).map(([position, value]) => (
                                                  <option
                                                    key={value[0]}
                                                    value={value[0]}
                                                  >
                                                    {value[0]}
                                                  </option>
                                                ))}
                                            </select>
                                          </td>
                                          <td className="trade-cell">
                                            {" "}
                                            Amount{" "}
                                          </td>
                                          <td className="trade-cell trade-cell-refresh">
                                            <button
                                              className="refresh-trades-button"
                                              onClick={fetchTradeOrders}
                                            >
                                              Refresh
                                            </button>
                                          </td>
                                        </tr>
                                        <tr className="spacing-row"></tr>
                                        {Array.isArray(tradeOrders) &&
                                        tradeOrders.length > 0 ? (
                                          tradeOrders.map(
                                            ([
                                              tradeId,
                                              {
                                                creator,
                                                payAmount,
                                                payCurrency,
                                                recAmount,
                                                recCurrency,
                                              },
                                            ]) => {
                                              const index =
                                                currencies.findIndex(
                                                  (currency) =>
                                                    currency[0] === payCurrency
                                                );

                                              const payColor =
                                                totalCurrencies.find(
                                                  ([curr]) =>
                                                    curr === payCurrency
                                                )?.[1]?.color;

                                              const recColor =
                                                totalCurrencies.find(
                                                  ([curr]) =>
                                                    curr === recCurrency
                                                )?.[1]?.color;

                                              if (
                                                outFilter !== "" &&
                                                payCurrency !== outFilter
                                              ) {
                                                return <></>;
                                              }
                                              if (
                                                inFilter !== "" &&
                                                recCurrency !== inFilter
                                              ) {
                                                return <></>;
                                              }

                                              return (
                                                <tr
                                                  className="trade-row"
                                                  key={tradeId}
                                                >
                                                  <td className="currency-cell">
                                                    <b>
                                                      <span
                                                        className="dot"
                                                        style={{
                                                          backgroundColor:
                                                            payColor,
                                                        }}
                                                      ></span>
                                                      {payCurrency}
                                                    </b>
                                                  </td>
                                                  <td> {payAmount} </td>
                                                  <td>
                                                    {" "}
                                                    <span>&#8594;</span>{" "}
                                                  </td>
                                                  <td className="currency-cell">
                                                    <b>
                                                      <span
                                                        className="dot"
                                                        style={{
                                                          backgroundColor:
                                                            recColor,
                                                        }}
                                                      ></span>
                                                      {recCurrency}
                                                    </b>
                                                  </td>
                                                  <td> {recAmount} </td>
                                                  <td className="execute-cell">
                                                    {creator !== username ? (
                                                      index !== -1 ? (
                                                        currencies[index][1] >=
                                                        payAmount ? (
                                                          <button
                                                            className="execute-button"
                                                            onClick={() =>
                                                              executeTrade(
                                                                tradeId
                                                              )
                                                            }
                                                          >
                                                            Execute
                                                          </button>
                                                        ) : (
                                                          <button className="execute-button execute-button-invalid invisble">
                                                            Execute
                                                          </button>
                                                        )
                                                      ) : (
                                                        <button className="execute-button execute-button-invalid invisble">
                                                          Execute
                                                        </button>
                                                      )
                                                    ) : (
                                                      <button
                                                        className="execute-button execute-button-cancel"
                                                        onClick={() =>
                                                          cancelTrade(tradeId)
                                                        }
                                                      >
                                                        Cancel
                                                      </button>
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan={6}
                                              className="empty-text"
                                            >
                                              <b>No Trades</b>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <h2>
                                  Create an username
                                </h2>
                                <b>$ </b>
                                <input
                                  type="text"
                                  value={selectedUsername}
                                  onChange={(e) =>
                                    setSelectedUsername(e.target.value)
                                  }
                                  placeholder="username here!"
                                  className="username-input"
                                />

                                <button onClick={createUsername}>
                                  Set Username
                                </button>
                              </div>
                            )}

                            {/* History Data 
                            
                            <h2 className="section-heading">History</h2>
                            <ul
                              className="history-list"
                              style={{
                                display: "flex",
                                flexDirection: "column-reverse",
                              }}
                            >
                              {Array.isArray(currenciesHistory) &&
                              currenciesHistory.length > 0 ? (
                                currenciesHistory.map(
                                  ([timestamp, { action, currency }]) => {
                                    const formattedTimestamp = new Date(
                                      Number(timestamp)
                                    ).toLocaleString();

                                    return (
                                      <li key={timestamp}>
                                        {action === 0 ? (
                                          <div>
                                            {formattedTimestamp} - Created
                                            currency <b>{currency}</b>
                                          </div>
                                        ) : (
                                          <div>
                                            {action > 0 ? (
                                              <div>
                                                {formattedTimestamp} - Issued{" "}
                                                {action} to <b>{currency}</b>
                                              </div>
                                            ) : (
                                              <div>
                                                {formattedTimestamp} - Removed{" "}
                                                {-action} from <b>{currency}</b>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </li>
                                    );
                                  }
                                )
                              ) : (
                                <li>No histories found.</li>
                              )}
                            </ul>
                            */}

                            {/* Only one user, hardcoded, can add and create currency */}
                            {user.uid === "NILyPVlxLYYuLjRoVxGhjFFXlnc2" && (
                              <div>
                                {/* Add Currency */}
                                <h2 className="section-heading">
                                  Add Currency:
                                </h2>
                                <div className="send-currency-form">
                                  <select
                                    className="select-menu no-left-margin"
                                    value={selectedCurrencyAdd}
                                    onChange={(e) =>
                                      setSelectedCurrencyAdd(e.target.value)
                                    }
                                  >
                                    <option value="">Currency</option>
                                    {typeof totalCurrencies === "object" &&
                                      totalCurrencies !== null &&
                                      Object.entries(totalCurrencies).map(
                                        ([position, value]) => (
                                          <option
                                            key={value[0]}
                                            value={value[0]}
                                          >
                                            {value[0]}
                                          </option>
                                        )
                                      )}
                                  </select>
                                  <input
                                    type="number"
                                    value={selectedAmountAdd}
                                    onChange={(e) =>
                                      setSelectedAmountAdd(e.target.value)
                                    }
                                    placeholder="Amount"
                                    className="amount-input"
                                  />
                                  <button
                                    className="add-currency-button"
                                    onClick={addCurrency}
                                  >
                                    Currency
                                  </button>
                                </div>

                                {/* Create Currency */}
                                <h2>Create Currency:</h2>
                                <div className="send-currency-form">
                                  <input
                                    type="text"
                                    value={currencyCreate}
                                    onChange={(e) =>
                                      setCurrencyCreate(e.target.value)
                                    }
                                    placeholder="Currency"
                                    className="name-input"
                                  />
                                  <button onClick={createCurrency}>
                                    Create Currency
                                  </button>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                        ) : (
                        <tr>
                          <td className="body-cell">
                            <h2>Balances</h2>
                            <table className="currencies-list">
                              <tbody>
                                {Array.isArray(currencies) &&
                                currencies.length > 0 ? (
                                  currencies.map(([currency, amount]) => {
                                    const colorIndex = totalCurrencies.find(
                                      ([curr]) => curr === currency
                                    )?.[1]?.color;

                                    return (
                                      <tr
                                        key={currency}
                                        className="balance-entry"
                                      >
                                        <td className="balance-td">
                                          <span
                                            className="dot dot-balance"
                                            style={{
                                              backgroundColor: colorIndex,
                                            }}
                                          ></span>
                                          <b>{currency}</b>{" "}
                                        </td>
                                        <td className="balance-td">{amount}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan={3}>No balances found.</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>

                            {username ? (
                              <div>
                                {/* Trades */}
                                <h2 className="section-heading">Buy Orders</h2>

                                {/* Display Trades */}
                                <div className="trade-section">
                                  <div className="create-trade">
                                    <b className="create-trade-text">
                                      Create Trade
                                    </b>
                                    <table className="trade-table">
                                      <tbody>
                                        {/* Only show Trades */}
                                        <tr className="create-trade-form">
                                          <td>
                                            <select
                                              className="select-menu secondary-input"
                                              value={currencyTradeOut}
                                              onChange={(e) =>
                                                setCurrencyTradeOut(
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option value="">Offering</option>
                                              {typeof currencies === "object" &&
                                                currencies !== null &&
                                                Object.entries(currencies).map(
                                                  ([position, value]) => (
                                                    <option
                                                      key={value[0]}
                                                      value={value[0]}
                                                    >
                                                      {value[0]}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                          </td>
                                          <td>
                                            <input
                                              type="number"
                                              value={tradeAmountOut}
                                              onChange={(e) =>
                                                setTradeAmountOut(
                                                  e.target.value
                                                )
                                              }
                                              placeholder="Amount"
                                              className="amount-input secondary-input"
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <span>&#8594;</span>{" "}
                                          </td>
                                          <td>
                                            <select
                                              className="select-menu secondary-input"
                                              value={currencyTradeIn}
                                              onChange={(e) =>
                                                setCurrencyTradeIn(
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option value="">Receive</option>
                                              {typeof totalCurrencies ===
                                                "object" &&
                                                totalCurrencies !== null &&
                                                Object.entries(
                                                  totalCurrencies
                                                ).map(([position, value]) => (
                                                  <option
                                                    key={value[0]}
                                                    value={value[0]}
                                                  >
                                                    {value[0]}
                                                  </option>
                                                ))}
                                            </select>
                                          </td>
                                          <td>
                                            <input
                                              type="number"
                                              value={tradeAmountIn}
                                              onChange={(e) =>
                                                setTradeAmountIn(e.target.value)
                                              }
                                              placeholder="Amount"
                                              className="amount-input secondary-input"
                                            />
                                          </td>

                                          <td> 
                                            {" "}
                                            <button
                                              className="create-order-button"
                                              onClick={createTradeOrder}
                                            >
                                              Create Order
                                            </button>{" "}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>

                                  <div className="trade-list">
                                    <table className="trade-table">
                                      <tbody>
                                        <tr className="trade-row">
                                          <td className="trade-cell">
                                            <select
                                              className="select-menu"
                                              value={outFilter}
                                              onChange={(e) =>
                                                setOutFilter(e.target.value)
                                              }
                                            >
                                              <option value="">Payment</option>
                                              {typeof currencies === "object" &&
                                                currencies !== null &&
                                                Object.entries(currencies).map(
                                                  ([position, value]) => (
                                                    <option
                                                      key={value[0]}
                                                      value={value[0]}
                                                    >
                                                      {value[0]}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                          </td>
                                          <td className="trade-cell">
                                            {" "}
                                            Amount{" "}
                                          </td>
                                          <td className="trade-cell"> </td>
                                          <td className="trade-cell">
                                            <select
                                              className="select-menu"
                                              value={inFilter}
                                              onChange={(e) =>
                                                setInFilter(e.target.value)
                                              }
                                            >
                                              <option value="">
                                                Receiving
                                              </option>
                                              {typeof totalCurrencies ===
                                                "object" &&
                                                totalCurrencies !== null &&
                                                Object.entries(
                                                  totalCurrencies
                                                ).map(([position, value]) => (
                                                  <option
                                                    key={value[0]}
                                                    value={value[0]}
                                                  >
                                                    {value[0]}
                                                  </option>
                                                ))}
                                            </select>
                                          </td>
                                          <td className="trade-cell">
                                            {" "}
                                            Amount{" "}
                                          </td>
                                          <td className="trade-cell trade-cell-refresh">
                                            <button
                                              className="refresh-trades-button"
                                              onClick={fetchTradeOrders}
                                            >
                                              Refresh
                                            </button>
                                          </td>
                                        </tr>
                                        <tr className="spacing-row"></tr>
                                        {Array.isArray(tradeOrders) &&
                                        tradeOrders.length > 0 ? (
                                          tradeOrders.map(
                                            ([
                                              tradeId,
                                              {
                                                creator,
                                                payAmount,
                                                payCurrency,
                                                recAmount,
                                                recCurrency,
                                              },
                                            ]) => {
                                              const index =
                                                currencies.findIndex(
                                                  (currency) =>
                                                    currency[0] === payCurrency
                                                );

                                              const payColor =
                                                totalCurrencies.find(
                                                  ([curr]) =>
                                                    curr === payCurrency
                                                )?.[1]?.color;

                                              const recColor =
                                                totalCurrencies.find(
                                                  ([curr]) =>
                                                    curr === recCurrency
                                                )?.[1]?.color;

                                              if (
                                                outFilter !== "" &&
                                                payCurrency !== outFilter
                                              ) {
                                                return <></>;
                                              }
                                              if (
                                                inFilter !== "" &&
                                                recCurrency !== inFilter
                                              ) {
                                                return <></>;
                                              }

                                              return (
                                                <tr
                                                  className="trade-row"
                                                  key={tradeId}
                                                >
                                                  <td className="currency-cell">
                                                    <b>
                                                      <span
                                                        className="dot"
                                                        style={{
                                                          backgroundColor:
                                                            payColor,
                                                        }}
                                                      ></span>
                                                      {payCurrency}
                                                    </b>
                                                  </td>
                                                  <td> {payAmount} </td>
                                                  <td>
                                                    {" "}
                                                    <span>&#8594;</span>{" "}
                                                  </td>
                                                  <td className="currency-cell">
                                                    <b>
                                                      <span
                                                        className="dot"
                                                        style={{
                                                          backgroundColor:
                                                            recColor,
                                                        }}
                                                      ></span>
                                                      {recCurrency}
                                                    </b>
                                                  </td>
                                                  <td> {recAmount} </td>
                                                  <td className="execute-cell">
                                                    {creator !== username ? (
                                                      index !== -1 ? (
                                                        currencies[index][1] >=
                                                        payAmount ? (
                                                          <button
                                                            className="execute-button"
                                                            onClick={() =>
                                                              executeTrade(
                                                                tradeId
                                                              )
                                                            }
                                                          >
                                                            Execute
                                                          </button>
                                                        ) : (
                                                          <button className="execute-button execute-button-invalid invisble">
                                                            Execute
                                                          </button>
                                                        )
                                                      ) : (
                                                        <button className="execute-button execute-button-invalid invisble">
                                                          Execute
                                                        </button>
                                                      )
                                                    ) : (
                                                      <button
                                                        className="execute-button execute-button-cancel"
                                                        onClick={() =>
                                                          cancelTrade(tradeId)
                                                        }
                                                      >
                                                        Cancel
                                                      </button>
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan={6}
                                              className="empty-text"
                                            >
                                              <b>No Trades</b>
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <h2>
                                  Create an username
                                </h2>
                                <b>$ </b>
                                <input
                                  type="text"
                                  value={selectedUsername}
                                  onChange={(e) =>
                                    setSelectedUsername(e.target.value)
                                  }
                                  placeholder="username here!"
                                  className="username-input"
                                />

                                <button onClick={createUsername}>
                                  Set Username
                                </button>
                              </div>
                            )}

                            {/* History Data 
                            <h2 className="section-heading">History</h2>
                            <ul
                              className="history-list"
                              style={{
                                display: "flex",
                                flexDirection: "column-reverse",
                              }}
                            >
                              {Array.isArray(currenciesHistory) &&
                              currenciesHistory.length > 0 ? (
                                currenciesHistory.map(
                                  ([timestamp, { action, currency }]) => {
                                    const formattedTimestamp = new Date(
                                      Number(timestamp)
                                    ).toLocaleString();

                                    return (
                                      <li key={timestamp}>
                                        {action === 0 ? (
                                          <div>
                                            {formattedTimestamp} - Created
                                            currency <b>{currency}</b>
                                          </div>
                                        ) : (
                                          <div>
                                            {action > 0 ? (
                                              <div>
                                                {formattedTimestamp} - Issued{" "}
                                                {action} to <b>{currency}</b>
                                              </div>
                                            ) : (
                                              <div>
                                                {formattedTimestamp} - Removed{" "}
                                                {-action} from <b>{currency}</b>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </li>
                                    );
                                  }
                                )
                              ) : (
                                <li>No histories found.</li>
                              )}
                            </ul> */}
                            </td>
                            <td className="body-cell body-cell-right padded-10px">
                            {username ? (
                              <div>
                                <h2 className="section-heading margin">
                                  Transactions
                                </h2>
                                <div className="send-currency-form">
                                  <div className="send-currency-input">
                                  <b>Send </b>

                                  <input
                                    type="number"
                                    value={selectedAmountSend}
                                    onChange={(e) =>
                                      setSelectedAmountSend(e.target.value)
                                    }
                                    placeholder="amount"
                                    className="amount-input amount-input-skinny"
                                  />

                                  <select
                                    className="select-menu"
                                    value={selectedCurrencySend}
                                    onChange={(e) =>
                                      setSelectedCurrencySend(e.target.value)
                                    }
                                  >
                                    <option value="">Currency</option>
                                    {typeof currencies === "object" &&
                                      currencies !== null &&
                                      Object.entries(currencies).map(
                                        ([position, value]) => (
                                          <option
                                            key={value[0]}
                                            value={value[0]}
                                          >
                                            {value[0]}
                                          </option>
                                        )
                                      )}
                                  </select>

                                  <b> to </b>

                                  <input
                                    type="text"
                                    value={selectedUser}
                                    onChange={(e) =>
                                      setSelectedUser(e.target.value)
                                    }
                                    placeholder="$user"
                                    className="username-input"
                                    list="friends"
                                  />
                                  <datalist id="friends">
                                    {friends.map((friend) => (
                                      <option value={friend} key={friend} />
                                    ))}
                                  </datalist>

                              </div>
                              
                                  <button
                                    className="send-currency-button"
                                    onClick={sendCurrency}
                                  >
                                    Send
                                  </button>
                                </div>

                                <OfferForm
                                  currencies={currencies}
                                  totalCurrencies={totalCurrencies}
                                  username={username}
                                  friends={friends}
                                  setFriends={setFriends}
                                  user={user}
                                  fetchCurrencies={fetchCurrencies}
                                  onOfferFormChange={handleOfferFormChange}
                                />

                                <OfferList
                                  username={username}
                                  fetchCurrencies={fetchCurrencies}
                                  currencies={currencies}
                                  user={user}
                                  offerFormChange={offerFormChange}
                                  totalCurrencies={totalCurrencies}
                                />
                              </div>
                            ) : (
                              <></>
                            )}

                            {/* Only one user, hardcoded, can add and create currency */}
                            {user.uid === "NILyPVlxLYYuLjRoVxGhjFFXlnc2" && (
                              <div>
                                {/* Add Currency */}
                                <h2 className="section-heading">
                                  Add Currency:
                                </h2>
                                <div className="send-currency-form">
                                  <select
                                    className="select-menu no-left-margin"
                                    value={selectedCurrencyAdd}
                                    onChange={(e) =>
                                      setSelectedCurrencyAdd(e.target.value)
                                    }
                                  >
                                    <option value="">Select currency</option>
                                    {typeof totalCurrencies === "object" &&
                                      totalCurrencies !== null &&
                                      Object.entries(totalCurrencies).map(
                                        ([position, value]) => (
                                          <option
                                            key={value[0]}
                                            value={value[0]}
                                          >
                                            {value[0]}
                                          </option>
                                        )
                                      )}
                                  </select>
                                  <input
                                    type="number"
                                    value={selectedAmountAdd}
                                    onChange={(e) =>
                                      setSelectedAmountAdd(e.target.value)
                                    }
                                    placeholder="Amount"
                                    className="amount-input"
                                  />
                                  <button
                                    className="add-currency-button"
                                    onClick={addCurrency}
                                  >
                                    Add Currency
                                  </button>
                                </div>

                                {/* Create Currency */}
                                <h2>Create Currency:</h2>
                                <div className="send-currency-form">
                                  <input
                                    type="text"
                                    value={currencyCreate}
                                    onChange={(e) =>
                                      setCurrencyCreate(e.target.value)
                                    }
                                    placeholder="Currency"
                                    className="name-input"
                                  />
                                  <button onClick={createCurrency}>
                                    Create Currency
                                  </button>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                        )}
                      </tbody>
                    </table>
                  </td>
                  {isMobile ? (<></>) : (<td></td>)}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="login-container">
          <br></br>
          <img
            src={require("./images/logo.png")}
            className="logo invert"
            alt="Logo for the Goob Exchange"
          />
          <br></br>
          <h1>Welcome to The Goob Exchange</h1>
          <button className="login-button" onClick={handleLogin}>
            Sign in with Google
          </button>
        </div>
      )}
    </div>
  );
};

export default App;
