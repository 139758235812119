import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

function OfferList({
  username,
  user,
  fetchCurrencies,
  currencies,
  offerFormChange,
  totalCurrencies,
}) {
  const [tabValue, setTabValue] = useState("Current Offers");
  const [sentOffers, setSentOffers] = useState([]);
  const [receivedOffers, setReceivedOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("List Load");
    fetchOffers();
  }, []);

  const fetchOffers = async () => {
    try {
      const offersRef = firebase.firestore().collection("offers");
      const sentQuerySnapshot = await offersRef
        .where("creator", "==", username)
        .get();
      const receivedQuerySnapshot = await offersRef
        .where("target", "==", username)
        .get();

      const sentOffersData = [];
      sentQuerySnapshot.forEach((doc) => {
        const offerData = {
          id: doc.id,
          ...doc.data(),
        };
        sentOffersData.push(offerData);
      });

      const receivedOffersData = [];
      receivedQuerySnapshot.forEach((doc) => {
        const offerData = {
          id: doc.id,
          ...doc.data(),
        };
        receivedOffersData.push(offerData);
      });

      await assignProfilePictures(receivedOffersData, sentOffersData);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  };

  const fetchProfilePicture = async (targetUsername) => {
    try {
      const usernameRef = firebase
        .firestore()
        .collection("usernames")
        .doc(targetUsername.toLowerCase());
      const usernameDoc = await usernameRef.get();

      if (!usernameDoc.exists) {
        throw new Error("Target not found");
      }

      const photoURL = usernameDoc.data().photoURL;
      return photoURL;
    } catch (error) {
      console.error("Error fetching profile picture:", error);
      throw error;
    }
  };

  const assignProfilePictures = async (receivedOffersData, sentOffersData) => {
    if (receivedOffersData.length > 0) {
      try {
        const updatedReceivedOffers = [];

        for (const offer of receivedOffersData) {
          try {
            const photoURL = await fetchProfilePicture(offer.creator);
            updatedReceivedOffers.push({
              ...offer,
              photoURL,
            });
          } catch (error) {
            console.error(
              `Error fetching profile picture for ${offer.creator}:`,
              error
            );
            updatedReceivedOffers.push({
              ...offer,
              photoURL: null,
            });
          }
        }

        setReceivedOffers(updatedReceivedOffers);
      } catch (error) {
        console.error("Error fetching profile pictures:", error);
      }
    } else {
      setReceivedOffers([]);
    }

    if (sentOffersData.length > 0) {
      try {
        const updatedSentOffers = [];

        for (const offer of sentOffersData) {
          try {
            const photoURL = await fetchProfilePicture(offer.target);
            updatedSentOffers.push({
              ...offer,
              photoURL,
            });
          } catch (error) {
            console.error(
              `Error fetching profile picture for ${offer.target}:`,
              error
            );
            updatedSentOffers.push({
              ...offer,
              photoURL: null,
            });
          }
        }
        setSentOffers(updatedSentOffers);
      } catch (error) {
        console.error("Error fetching profile pictures:", error);
      }
    } else {
      setSentOffers([]);
    }
  };

  const handleOfferTab = (event) => {
    const { value } = event.target;
    setTabValue(value);
  };

  const handleExecute = async (offerId) => {
    const offerDocRef = firebase.firestore().collection("offers").doc(offerId);
    const offerDoc = await offerDocRef.get();

    if (offerDoc.exists) {
      const offerData = offerDoc.data();

      const index = currencies.findIndex(
        (currency) => currency[0] === offerData.payCurrency
      );

      if (index === -1) {
        // Don't have any currency
        return;
      }

      if (offerData.payAmount > currencies[index][1]) {
        return;
      }

      const userDocRef = firebase.firestore().collection("users").doc(user.uid);
      const userDoc = await userDocRef.get();

      if (userDoc.exists) {
        const userData = userDoc.data();
        let executingCurrencies = userData.currencies || {};
        const enteredAmount = parseInt(offerData.offerAmount, 10);

        if (executingCurrencies.hasOwnProperty(offerData.offerCurrency)) {
          // Currency already exists, add the entered amount to the current value
          const currentValue = executingCurrencies[offerData.offerCurrency];
          const newValue = currentValue + enteredAmount;
          if (newValue < 0) {
            return;
          }
          executingCurrencies[offerData.offerCurrency] = newValue;
        } else {
          // Currency doesn't exist, add it with the entered amount as the value
          executingCurrencies[offerData.offerCurrency] = enteredAmount;
        }

        if (executingCurrencies.hasOwnProperty(offerData.payCurrency)) {
          // Currency already exists, add the entered amount to the current value
          const currentValue = executingCurrencies[offerData.payCurrency];
          const enteredAmount = parseInt(offerData.payAmount, 10);
          const recNameRef = firebase
            .firestore()
            .collection("usernames")
            .doc(offerData.creator.toLowerCase());
          const recNameDoc = await recNameRef.get();

          if (recNameDoc.exists) {
            const recipientRef = firebase
              .firestore()
              .collection("users")
              .doc(recNameDoc.data().userId);
            const recipientDoc = await recipientRef.get();

            if (recipientDoc.exists) {
              const recipientData = recipientDoc.data();
              const recCurrencies = recipientData.currencies || {};

              if (recCurrencies.hasOwnProperty(offerData.payCurrency)) {
                recCurrencies[offerData.payCurrency] =
                  recCurrencies[offerData.payCurrency] + enteredAmount;
              } else {
                recCurrencies[offerData.payCurrency] = enteredAmount;
              }

              executingCurrencies = recCurrencies;
              recipientRef.update({ currencies: executingCurrencies });
              executingCurrencies = userData.currencies || {};
            }
          }

          // Take currency from user
          executingCurrencies[offerData.payCurrency] =
            currentValue - enteredAmount;
          if (executingCurrencies[offerData.payCurrency] === 0) {
            delete executingCurrencies[offerData.payCurrency];
          }
        }

        await userDocRef.update({ currencies: executingCurrencies });
        removeOffer(offerId);
        fetchCurrencies(user.uid);
      }
    }

    fetchOffers();
  };

  const handleCancel = async (offerId) => {
    const offerDocRef = firebase.firestore().collection("offers").doc(offerId);
    const offerDoc = await offerDocRef.get();

    if (offerDoc.exists) {
      const offerData = offerDoc.data();
      const usernameDocRef = firebase
        .firestore()
        .collection("usernames")
        .doc(offerData.creator || "");
      const usernameDoc = await usernameDocRef.get();

      if (usernameDoc.exists) {
        const usernameData = usernameDoc.data();
        const userDocRef = firebase
          .firestore()
          .collection("users")
          .doc(usernameData.userId || "");
        const userDoc = await userDocRef.get();

        if (userDoc.exists) {
          const userData = userDoc.data();
          const currencies = userData.currencies || {};
          const enteredAmount = parseInt(offerData.offerAmount, 10);

          if (currencies.hasOwnProperty(offerData.offerCurrency)) {
            // Currency already exists, add the entered amount to the current value
            const currentValue = currencies[offerData.offerCurrency];
            const newValue = currentValue + enteredAmount;
            if (newValue < 0) {
              return;
            }
            currencies[offerData.offerCurrency] = newValue;
          } else {
            // Currency doesn't exist, add it with the entered amount as the value
            currencies[offerData.offerCurrency] = enteredAmount;
          }

          await userDocRef.update({ currencies });
          removeOffer(offerId);
          fetchOffers();
          fetchCurrencies(user.uid);
        }
      }
    }

    fetchOffers();
  };

  const removeOffer = async (offerId) => {
    try {
      const offerRef = firebase.firestore().collection("offers").doc(offerId);
      await offerRef.delete();
      fetchOffers();
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="send-currency-form margin">
      <table className="trade-table">
        <tbody>
          <tr className="offer-tabs">
            <td className="trade-cell offer-tab">
              <button
                value="Current Offers"
                className={
                  tabValue === "Current Offers"
                    ? "tab-button"
                    : "tab-button tab-button-inactive"
                }
                onClick={handleOfferTab}
              >
                Current Offers
              </button>
            </td>
            <td className="trade-cell offer-tab">
              <button
                value="My Offers"
                className={
                  tabValue === "Current Offers"
                    ? "tab-button tab-button-inactive"
                    : "tab-button"
                }
                onClick={handleOfferTab}
              >
                My Offers
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <table className="trade-table">
        <tbody>
          <tr className="spacing-row"></tr>
          {tabValue === "Current Offers" ? (
            receivedOffers.length > 0 ? (
              receivedOffers.map((offer, index) => {
                const payColor = totalCurrencies.find(
                  ([curr]) => curr === offer.payCurrency
                )?.[1]?.color;

                const offerColor = totalCurrencies.find(
                  ([curr]) => curr === offer.offerCurrency
                )?.[1]?.color;

                return (
                  <tr className="trade-row" key={`received-offer-${index}`}>
                    <td className="profile-cell">
                      {offer.photoURL && (
                        <img
                          src={offer.photoURL}
                          alt={offer.creator}
                          className="profile-icon"
                          title={offer.creator}
                        />
                      )}
                    </td>

                    <td>
                      <b>
                        <span
                          className="dot"
                          style={{
                            backgroundColor: payColor,
                          }}
                        ></span>
                        {offer.payCurrency}
                      </b>
                    </td>
                    <td>{offer.payAmount}</td>
                    <td className="trade-cell-arrow">
                      <span>&#8594;</span>{" "}
                    </td>
                    <td>
                      <b>
                        <span
                          className="dot"
                          style={{
                            backgroundColor: offerColor,
                          }}
                        ></span>
                        {offer.offerCurrency}
                      </b>
                    </td>
                    <td>{offer.offerAmount}</td>
                    <td
                      className="offer-action-cell"
                      onClick={() => handleExecute(offer.id)}
                    >
                      <button className="offer-action-button">&#10003;</button>
                    </td>
                    <td className="offer-action-cell">
                      <button
                        className="offer-action-button"
                        onClick={() => handleCancel(offer.id)}
                      >
                        &#x2715;
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr key="no-offers-received">
                <td colSpan="8">
                  <br />
                  <b>No offers found.</b>
                  <br /><br />
                </td>
              </tr>
            )
          ) : sentOffers.length > 0 ? (
            sentOffers.map((offer, index) => {
              const payColor = totalCurrencies.find(
                ([curr]) => curr === offer.payCurrency
              )?.[1]?.color;

              const offerColor = totalCurrencies.find(
                ([curr]) => curr === offer.offerCurrency
              )?.[1]?.color;

              return (
                <tr className="trade-row" key={`sent-offer-${index}`}>
                  <td className="profile-cell">
                    {offer.photoURL && (
                      <img
                        src={offer.photoURL}
                        alt={offer.target}
                        className="profile-icon"
                        title={offer.target}
                      />
                    )}
                  </td>
                  <td>
                    <b>
                      {" "}
                      <span
                        className="dot"
                        style={{
                          backgroundColor: offerColor,
                        }}
                      ></span>
                      {offer.offerCurrency}
                    </b>
                  </td>
                  <td>{offer.offerAmount}</td>
                  <td>
                    <span>&#8594;</span>{" "}
                  </td>
                  <td>
                    <b>
                      <span
                        className="dot"
                        style={{
                          backgroundColor: payColor,
                        }}
                      ></span>
                      {offer.payCurrency}
                    </b>
                  </td>
                  <td>{offer.payAmount}</td>
                  <td className="offer-action-cell">
                    <button className="offer-action-button invisble"></button>
                  </td>
                  <td className="offer-action-cell">
                    <button
                      className="offer-action-button"
                      onClick={() => handleCancel(offer.id)}
                    >
                      &#x2715;
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr key="no-offers-sent">
              <td colSpan="8">
                <br />
                <b>No offers found.</b>
                <br /><br />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default OfferList;
